import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@x/common/icon';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { ContentRefDialogComponent } from './components/content-ref-dialog/content-ref-dialog.component';
import { ContentDialogService } from './services/content-dialog.service';

@NgModule({
  declarations: [ContentRefDialogComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    DashboardCoreModule,
    DashboardFormModule,
    IconModule,
  ],
  exports: [],
  providers: [ContentDialogService],
})
export class ContentSharedModule {}
